<template>
  <div class="gst-panel-toolbar">
    <div class="gst-panel-box-toolbar">
        <slot name="toolbar"></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'GstPanelToolbar'
}
</script>

<style scoped>
@import '../../assets/styles/gst.panel.style.css';
</style>
